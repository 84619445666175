import React, { Fragment, useContext, useEffect, useState } from "react"

import AdminLayout from "../layouts/admin"
import SEO from "../components/seo"
import Loader from "../components/loader"

import {
  Alert,
  Card,
  Col,
  Row,
  Statistic,
  Button,
  Tag,
  Comment,
  List,
  Modal,
  Pagination,
} from "antd"

import { StationContext } from "../contexts/stationContext"
import { ResponseContext } from "../contexts/responseContext"
import { AuthContext } from "../components/auth/authContext"

import { ExclamationCircleOutlined } from "@ant-design/icons"

import HeadImage from "../images/hitstream-icon.png"

const AdminPage = () => {
  const {
    onlineStation,
    onlineStationError,
    onlineStationLoading
  } = useContext(StationContext)
  const {
    currentPage,
    total,
    pageSize,
    responses,
    responsesLoading,
    responsesError,
    getResponses,
    removeResponse,
    responseRemoving,
    responseRemovingError,
    acceptResponse,
    responseAccepting,
    responseAcceptingError,
  } = useContext(ResponseContext)

  const { state: authState } = useContext(AuthContext)

  useEffect(() => {
    getResponses(false, 1, 10, { accepted: false })
  }, [])

  const acceptResponseItem = async responseToAccept => {
    Modal.confirm({
      title: "Weet je het zeker",
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je de reactie van '${responseToAccept.name}' wilt accepteren?`,
      onOk: async () => {
        const accepted = await acceptResponse(responseToAccept)
        if (accepted) {
          getResponses(true, 1, 10, { accepted: false })
        } else {
          alert("An error occured when accepting the response")
        }
      },
    })
  }

  const removeResponseItem = async responseToDelete => {
    Modal.confirm({
      title: "Weet je het zeker",
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je de reactie van '${responseToDelete.name}' wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
      onOk: async () => {
        const removed = await removeResponse(responseToDelete)
        if (removed) {
          getResponses(true, 100)
        } else {
          alert("An error occured when removing the response")
        }
      },
    })
  }

  return (
    <AdminLayout
      noBackground={true}
      breadcrumbs={[{ link: "/admin", title: "Home" }]}
    >
      <SEO title="Admin" />

      {!onlineStationError && !onlineStationLoading && onlineStation && (
        <Fragment>
          {onlineStation._id === authState.stationId && (
            <Alert
              message="Je bent online"
              description={`Momenteel ben je online met ${onlineStation.name}`}
              type="success"
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
        </Fragment>
      )}

      <Row gutter={16}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Card
            title={
              <Fragment>
                {!onlineStationError && !onlineStationLoading && onlineStation
                  ? onlineStation.name
                  : "Huidig station"}
                <Tag style={{ marginLeft: 8 }} color="#87d068">
                  Online
                </Tag>
              </Fragment>
            }
            bordered={false}
          >
            {onlineStationLoading && <Loader />}

            {!onlineStationError && !onlineStationLoading && onlineStation && (
              <Fragment>
                <Row gutter={16}>
                  <Col span={12}>
                    <Statistic
                      title="# luisteraars"
                      value={onlineStation.currentlisteners}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="# piek luisteraars"
                      value={onlineStation.peaklisteners}
                    />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Card>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Card title="Laatste reacties" bordered={false}>
            {responsesLoading && <Loader />}

            {!responsesLoading && responses && (
              <Fragment>
                {responses.length === 0 && (
                  <p
                    style={{ fontSize: 16, marginTop: 16, textAlign: "center" }}
                  >
                    Nog geen reacties
                  </p>
                )}

                <List
                  className="comment-list"
                  itemLayout="horizontal"
                  dataSource={responses}
                  renderItem={response => (
                    <li>
                      <Comment
                        actions={
                          !response.accepted
                            ? [
                                <Button
                                  onClick={() => acceptResponseItem(response)}
                                  style={{
                                    background: "#87d068",
                                    borderColor: "#87d068",
                                    marginRight: 4,
                                  }}
                                  size="small"
                                  type="primary"
                                >
                                  Bericht accepteren
                                </Button>,
                                <Button
                                  onClick={() => removeResponseItem(response)}
                                  size="small"
                                  type="primary"
                                  danger
                                >
                                  Afkeuren en verwijderen
                                </Button>,
                              ]
                            : [
                                <Button
                                  onClick={() => removeResponseItem(response)}
                                  size="small"
                                  type="primary"
                                  danger
                                >
                                  Verwijderen
                                </Button>,
                              ]
                        }
                        author={`${response.name} - ${response.email}`}
                        avatar={HeadImage}
                        content={response.text}
                        datetime={`
                          ${new Date(response.dateTime).getDate()}-${
                          new Date(response.dateTime).getMonth() + 1
                        }-${new Date(response.dateTime).getFullYear()}
                        `}
                      />
                    </li>
                  )}
                />

                <Pagination
                  simple
                  pageSize={pageSize}
                  total={total}
                  onChange={page => getResponses(true, page)}
                  current={currentPage}
                />

                <Button
                  onClick={() => getResponses(true, 1)}
                  style={{ marginTop: 16 }}
                  type="primary"
                >
                  Refresh
                </Button>
              </Fragment>
            )}
          </Card>
        </Col>
      </Row>
    </AdminLayout>
  )
}

export default AdminPage
